<!--

    Copyright © 2016-2022 The Thingsboard Authors

    Licensed under the Apache License, Version 2.0 (the "License");
    you may not use this file except in compliance with the License.
    You may obtain a copy of the License at

        http://www.apache.org/licenses/LICENSE-2.0

    Unless required by applicable law or agreed to in writing, software
    distributed under the License is distributed on an "AS IS" BASIS,
    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
    See the License for the specific language governing permissions and
    limitations under the License.

-->
<form [formGroup]="colorPickerFormGroup" (ngSubmit)="select()">
  <div mat-dialog-content fxLayout="row" fxLayoutAlign="center">
    <span [style.background]="colorPickerFormGroup.get('color').value"
          [cpToggle]="true"
          [cpDialogDisplay]="'inline'"
          [colorPicker]="colorPickerFormGroup.get('color').value"
          (colorPickerChange)="onColorChange($event)">
    </span>
  </div>
  <div mat-dialog-actions fxLayout="row">
    <span fxFlex></span>
    <button mat-button
            type="button"
            [disabled]="(isLoading$ | async)"
            (click)="cancel()">
      {{ 'action.cancel' | translate }}
    </button>
    <button mat-button
            type="submit"
            [disabled]="(isLoading$ | async) || colorPickerFormGroup.invalid || !colorPickerFormGroup.dirty">
      {{ 'action.select' | translate }}
    </button>
  </div>
</form>
