<!--

    Copyright © 2016-2022 The Thingsboard Authors

    Licensed under the Apache License, Version 2.0 (the "License");
    you may not use this file except in compliance with the License.
    You may obtain a copy of the License at

        http://www.apache.org/licenses/LICENSE-2.0

    Unless required by applicable law or agreed to in writing, software
    distributed under the License is distributed on an "AS IS" BASIS,
    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
    See the License for the specific language governing permissions and
    limitations under the License.

-->
<div class="tb-login-content mat-app-background tb-dark" fxFlex fxLayoutAlign="center center">
  <mat-card style="max-height: 80vh; overflow-y: auto;">
    <mat-card-content>
      <form class="tb-login-form" [formGroup]="loginFormGroup" (ngSubmit)="login()">
        <fieldset [disabled]="isLoading$ | async" fxLayout="column">
          <div fxLayout="column" fxLayoutAlign="start center" style="padding: 15px 0;">
            <tb-logo class="login-logo"></tb-logo>
          </div>
          <mat-progress-bar color="warn" mode="indeterminate" *ngIf="isLoading$ | async">
          </mat-progress-bar>
          <span style="height: 4px;" *ngIf="!(isLoading$ | async)"></span>
          <div tb-toast fxLayout="column" class="layout-padding">
            <span style="height: 50px;"></span>
            <div class="oauth-container tb-default" fxLayout="column" fxLayoutGap="16px" *ngIf="oauth2Clients?.length">
              <ng-container *ngFor="let oauth2Client of oauth2Clients">
                <a mat-raised-button class="login-with-button" href="{{ oauth2Client.url }}">
                  <mat-icon class="icon" svgIcon="{{ oauth2Client.icon }}"></mat-icon>
                  {{ 'login.login-with' | translate: {name: oauth2Client.name} }}
                </a>
              </ng-container>
              <div class="container-divider">
                <div class="line"><mat-divider></mat-divider></div>
                <div class="text mat-typography">{{ "login.or" | translate | uppercase }}</div>
                <div class="line"><mat-divider></mat-divider></div>
              </div>
            </div>
            <mat-form-field>
              <mat-label translate>login.username</mat-label>
              <input id="username-input" matInput type="email" autofocus formControlName="username" email required/>
              <mat-icon matPrefix>email</mat-icon>
              <mat-error *ngIf="loginFormGroup.get('username').invalid">
                {{ 'user.invalid-email-format' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>common.password</mat-label>
              <input id="password-input" matInput type="password" formControlName="password"/>
              <tb-toggle-password matSuffix></tb-toggle-password>
              <mat-icon matPrefix>lock</mat-icon>
            </mat-form-field>
            <div fxLayoutAlign="end center" class="forgot-password">
              <button class="tb-reset-password" mat-button type="button" routerLink="/login/resetPasswordRequest">{{ 'login.forgot-password' | translate }}
              </button>
            </div>
            <div fxLayout="column" class="tb-action-button">
              <button mat-raised-button color="accent" [disabled]="(isLoading$ | async)"
                      type="submit">{{ 'login.login' | translate }}</button>
            </div>
          </div>
        </fieldset>
      </form>
    </mat-card-content>
  </mat-card>
</div>
