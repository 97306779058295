<!--

    Copyright © 2016-2022 The Thingsboard Authors

    Licensed under the Apache License, Version 2.0 (the "License");
    you may not use this file except in compliance with the License.
    You may obtain a copy of the License at

        http://www.apache.org/licenses/LICENSE-2.0

    Unless required by applicable law or agreed to in writing, software
    distributed under the License is distributed on an "AS IS" BASIS,
    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
    See the License for the specific language governing permissions and
    limitations under the License.

-->
<section fxLayout="row">
  <section fxLayout="column" [fxShow]="isEdit">
    <label class="tb-small hide-label" translate>timewindow.hide</label>
    <mat-checkbox [(ngModel)]="hideFlag" (ngModelChange)="onHideFlagChange()"></mat-checkbox>
  </section>
  <section class="interval-section" fxLayout="column" fxFlex [fxShow]="advanced && (isEdit || !hideFlag)">
    <label class="tb-small interval-label" translate>{{ predefinedName }}</label>
    <section fxLayout="row wrap" fxLayoutAlign="start start" fxFlex fxLayoutGap="6px">
      <mat-form-field class="number-input">
        <mat-label translate>timeinterval.days</mat-label>
        <input matInput [disabled]="hideFlag || disabled" type="number" step="1" min="0" [(ngModel)]="days" (ngModelChange)="onTimeInputChange('days')"/>
      </mat-form-field>
      <mat-form-field class="number-input">
        <mat-label translate>timeinterval.hours</mat-label>
        <input matInput [disabled]="hideFlag || disabled" type="number" step="1" [(ngModel)]="hours" (ngModelChange)="onTimeInputChange('hours')"/>
      </mat-form-field>
      <mat-form-field class="number-input">
        <mat-label translate>timeinterval.minutes</mat-label>
        <input matInput [disabled]="hideFlag || disabled" type="number" step="1" [(ngModel)]="mins" (ngModelChange)="onTimeInputChange('mins')"/>
      </mat-form-field>
      <mat-form-field class="number-input">
        <mat-label translate>timeinterval.seconds</mat-label>
        <input matInput [disabled]="hideFlag || disabled" type="number" step="1" [(ngModel)]="secs" (ngModelChange)="onTimeInputChange('secs')"/>
      </mat-form-field>
    </section>
  </section>
  <section class="interval-section" fxLayout="row" fxFlex [fxShow]="!advanced && (isEdit || !hideFlag)">
    <mat-form-field fxFlex>
      <mat-label translate>{{ predefinedName }}</mat-label>
      <mat-select [disabled]="hideFlag || disabled" [(ngModel)]="intervalMs" (ngModelChange)="onIntervalMsChange()" style="min-width: 150px;">
        <mat-option *ngFor="let interval of intervals" [value]="interval.value">
          {{ interval.name | translate:interval.translateParams }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </section>
  <section fxLayout="column" fxLayoutAlign="center center" [fxShow]="(isEdit || !hideFlag)">
    <label class="tb-small advanced-label" translate>timeinterval.advanced</label>
    <mat-slide-toggle [disabled]="hideFlag || disabled" class="advanced-switch" [(ngModel)]="advanced" (ngModelChange)="onAdvancedChange()"></mat-slide-toggle>
  </section>
</section>
