<!--

    Copyright © 2016-2022 The Thingsboard Authors

    Licensed under the Apache License, Version 2.0 (the "License");
    you may not use this file except in compliance with the License.
    You may obtain a copy of the License at

        http://www.apache.org/licenses/LICENSE-2.0

    Unless required by applicable law or agreed to in writing, software
    distributed under the License is distributed on an "AS IS" BASIS,
    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
    See the License for the specific language governing permissions and
    limitations under the License.

-->
<section fxLayout="column" fxLayoutAlign="start stretch">
  <section fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px"
           fxLayout.xs="column" fxLayoutAlign.xs="start stretch"  fxLayoutGap.xs="0">
    <mat-form-field>
      <mat-placeholder translate>datetime.date-from</mat-placeholder>
      <mat-datetimepicker-toggle [for]="startDatePicker" matPrefix></mat-datetimepicker-toggle>
      <mat-datetimepicker #startDatePicker type="date" openOnFocus="true"></mat-datetimepicker>
      <input matInput [disabled]="disabled" [(ngModel)]="startDate" [matDatetimepicker]="startDatePicker" (ngModelChange)="onStartDateChange()">
    </mat-form-field>
    <mat-form-field>
      <mat-placeholder translate>datetime.time-from</mat-placeholder>
      <mat-datetimepicker-toggle [for]="startTimePicker" matPrefix></mat-datetimepicker-toggle>
      <mat-datetimepicker #startTimePicker type="time" openOnFocus="true"></mat-datetimepicker>
      <input matInput [disabled]="disabled" [(ngModel)]="startDate" [matDatetimepicker]="startTimePicker" (ngModelChange)="onStartDateChange()">
    </mat-form-field>
  </section>
  <section fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px"
           fxLayout.xs="column" fxLayoutAlign.xs="start stretch"  fxLayoutGap.xs="0">
    <mat-form-field>
      <mat-placeholder translate>datetime.date-to</mat-placeholder>
      <mat-datetimepicker-toggle [for]="endDatePicker" matPrefix></mat-datetimepicker-toggle>
      <mat-datetimepicker #endDatePicker type="date" openOnFocus="true"></mat-datetimepicker>
      <input matInput [disabled]="disabled" [(ngModel)]="endDate" [matDatetimepicker]="endDatePicker" (ngModelChange)="onEndDateChange()">
    </mat-form-field>
    <mat-form-field>
      <mat-placeholder translate>datetime.time-to</mat-placeholder>
      <mat-datetimepicker-toggle [for]="endTimePicker" matPrefix></mat-datetimepicker-toggle>
      <mat-datetimepicker #endTimePicker type="time" openOnFocus="true"></mat-datetimepicker>
      <input matInput [disabled]="disabled" [(ngModel)]="endDate" [matDatetimepicker]="endTimePicker" (ngModelChange)="onEndDateChange()">
    </mat-form-field>
  </section>
</section>
